import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const DorAdoMARSD: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/DorAdoMARS-D',
    name: 'DorAdo_mars_d',
    title: 'MARS-D',
    status: 'active',
    item: [
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'header_1',
            prefix: '0_0',
            required: true,
            text: 'Medication Adherence Report Scale - D'
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'info_1',
            prefix: '0_1',
            required: true,
            text: 'Viele Leute nehmen ihre Medikamente so ein, wie sie am besten damit zu Recht kommen. Dies weicht vielleicht von dem ab, was der Arzt ihnen gesagt hat oder von dem, was im Beipackzettel steht. Wir möchten gerne von Ihnen erfahren, wie Sie selbst Ihre Medikamente einnehmen. Hier finden Sie Aussagen anderer Leute zur Medikamenteneinnahme.'
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'info_1',
            prefix: '0_2',
            required: true,
            text: 'Bitte kreuzen Sie zu jeder Aussage das Kästchen an, das bei Ihnen am ehesten zutrifft.'
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'header_2',
            prefix: '0_3',
            required: true,
            text: 'Ihre eigene Art, Medikamente einzunehmen'
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'mars_d1',
            prefix: '1',
            required: true,
            text: 'Ich vergesse sie einzunehmen',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Immer',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Oft',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Manchmal',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Selten',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '5',
                        version: '1.0',
                        display: 'Nie',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'mars_d2',
            prefix: '2',
            required: true,
            text: 'Ich verändere die Dosis',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Immer',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Oft',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Manchmal',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Selten',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '5',
                        version: '1.0',
                        display: 'Nie',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'mars_d3',
            prefix: '3',
            required: true,
            text: 'Ich setze sie eine Weile lang aus',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Immer',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Oft',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Manchmal',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Selten',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '5',
                        version: '1.0',
                        display: 'Nie',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'mars_d4',
            prefix: '4',
            required: true,
            text: 'Ich lasse bewusst eine Dosis aus',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Immer',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Oft',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Manchmal',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Selten',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '5',
                        version: '1.0',
                        display: 'Nie',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'mars_d5',
            prefix: '5',
            required: true,
            text: 'Ich nehme weniger als verordnet ein',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Immer',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Oft',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Manchmal',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Selten',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                },
                {
                    valueCoding: {
                        code: '5',
                        version: '1.0',
                        display: 'Nie',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency2_de'
                    }
                }
            ]
        }
    ]
};
