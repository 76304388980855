import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const DorAdoMAUQ: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/MAUQ',
    name: 'mauq',
    title: 'MAUQ',
    status: 'active',
    item: [
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'mauq',
            prefix: '0',
            required: true,
            text: 'Usability-Befragung'
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'mauq_intro',
            prefix: '0_1',
            required: true,
            text: 'Bitte bewerten Sie die nachfolgenden Aussagen, um Ihre bisherigen Erfahrungen mit der DorAdo-App zu beschreiben.'
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_1',
            prefix: '1',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_e1',
                    prefix: '1_1',
                    required: false,
                    text: 'Die App war einfach zu bedienen.',
                    enableWhen: [
                        {
                            question: 'mauq_e1na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_e1na',
                    prefix: '1_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_2',
            prefix: '2',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_e2',
                    prefix: '2_1',
                    required: false,
                    text: 'Ich fand es einfach, die Nutzung der App zu erlernen.',
                    enableWhen: [
                        {
                            question: 'mauq_e2na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_e2na',
                    prefix: '2_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_3',
            prefix: '3',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_e3',
                    prefix: '3_1',
                    required: false,
                    text: 'Die Navigation auf den einzelnen Seiten der App war einheitlich.',
                    enableWhen: [
                        {
                            question: 'mauq_e3na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_e3na',
                    prefix: '3_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_4',
            prefix: '4',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_e4',
                    prefix: '4_1',
                    required: false,
                    text: 'Das Interface (die Benutzeroberfläche) der App ermöglichte mir, alle von der App gebotenen Funktionen zu nutzen (wie z. B. das Eingeben von Informationen, Reagieren auf Erinnerungen, Ansehen von Informationen).',
                    enableWhen: [
                        {
                            question: 'mauq_e4na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_e4na',
                    prefix: '4_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_5',
            prefix: '5',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_e5',
                    prefix: '5_1',
                    required: false,
                    text: 'Wenn ich bei der Nutzung der App etwas falsch gemacht hatte, konnte ich mich einfach und schnell wieder zurechtfinden.',
                    enableWhen: [
                        {
                            question: 'mauq_e5na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_e5na',
                    prefix: '5_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_6',
            prefix: '6',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i1',
                    prefix: '6_1',
                    required: false,
                    text: 'Mir gefällt das Interface (die Benutzeroberfläche) der App.',
                    enableWhen: [
                        {
                            question: 'mauq_i1na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i1na',
                    prefix: '6_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_7',
            prefix: '7',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i2',
                    prefix: '7_1',
                    required: false,
                    text: 'Der Organisation der Informationen in der App war gut, so dass ich die gewünschten Informationen problemlos finden konnte.',
                    enableWhen: [
                        {
                            question: 'mauq_i2na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i2na',
                    prefix: '7_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_8',
            prefix: '8',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i3',
                    prefix: '8_1',
                    required: false,
                    text: 'Die App bestätigte Informationen hinreichend und informierte mich über meinen Aktionen.',
                    enableWhen: [
                        {
                            question: 'mauq_i3na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '+',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i3na',
                    prefix: '8_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_9',
            prefix: '9',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i4',
                    prefix: '9_1',
                    required: false,
                    text: 'Ich fühle mich wohl dabei, die App im gesellschaftlichen Umfeld zu verwenden.',
                    enableWhen: [
                        {
                            question: 'mauq_i4na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i4na',
                    prefix: '9_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_10',
            prefix: '10',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i5',
                    prefix: '10_1',
                    required: false,
                    text: 'Den für die Nutzung dieser App erforderlichen Zeitaufwand finde ich in Ordnung.',
                    enableWhen: [
                        {
                            question: 'mauq_i5na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i5na',
                    prefix: '10_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_11',
            prefix: '11',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i6',
                    prefix: '11_1',
                    required: false,
                    text: 'Ich würde diese App wieder nutzen.',
                    enableWhen: [
                        {
                            question: 'mauq_i6na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i6na',
                    prefix: '11_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_12',
            prefix: '12',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i7',
                    prefix: '12_1',
                    required: false,
                    text: 'Ich bin insgesamt zufrieden mit dieser App.',
                    enableWhen: [
                        {
                            question: 'mauq_i7na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_i7na',
                    prefix: '12_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_13',
            prefix: '13',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u1',
                    prefix: '13_1',
                    required: false,
                    text: 'Die App wäre nützlich für meine Gesundheit und mein Wohlbefinden.',
                    enableWhen: [
                        {
                            question: 'mauq_u1na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u1na',
                    prefix: '13_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_14',
            prefix: '14',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u2',
                    prefix: '14_1',
                    required: false,
                    text: 'Die App hat meinen Zugang zu Gesundheitsdienstleistungen verbessert.',
                    enableWhen: [
                        {
                            question: 'mauq_u2na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u2na',
                    prefix: '14_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_15',
            prefix: '15',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u3',
                    prefix: '15_1',
                    required: false,
                    text: 'Die App hat mir geholfen, effektiv für meine Gesundheit zu sorgen.',
                    enableWhen: [
                        {
                            question: 'mauq_u3na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u3na',
                    prefix: '15_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_16',
            prefix: '16',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u4',
                    prefix: '16_1',
                    required: false,
                    text: 'Diese App verfügt über alle Funktionen und Fähigkeiten, die ich von ihr erwartet habe.',
                    enableWhen: [
                        {
                            question: 'mauq_u4na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu7',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u4na',
                    prefix: '16_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_17',
            prefix: '17',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u5',
                    prefix: '17_1',
                    required: false,
                    text: 'Ich konnte die App auch dann verwenden, wenn die Internetverbindung schlecht oder nicht verfügbar war.',
                    enableWhen: [
                        {
                            question: 'mauq_u5na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u5na',
                    prefix: '17_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'mauq_18',
            prefix: '18',
            required: true,
            item: [
                {
                    type: 'slider',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u6',
                    prefix: '18_1',
                    required: false,
                    text: 'Die App bietet eine akzeptable Möglichkeit, Gesundheitsdienstleistungen zu erhalten, z.B. den Zugriff auf Schulungsmaterialien, die Verfolgung meiner eigenen Aktivitäten und die Durchführung von Selbstbewertungen.',
                    enableWhen: [
                        {
                            question: 'mauq_u6na',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Stimme nicht zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '5',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '6',
                                version: '1.0',
                                display: '',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '7',
                                version: '1.0',
                                display: 'Stimme zu',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/AgreeDoNotAgree_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'mauq_u6na',
                    prefix: '18_2',
                    required: false,
                    text: '',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '99',
                                version: '1.0',
                                display: 'Nicht zutreffend',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotApplicable_de'
                            }
                        }
                    ]
                }
            ]
        }
    ]
};
