import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const PainAssesmentRegistrierung: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/PainAssesmentRegistrierung',
    title: 'Schmerzerfassung Registrierung',
    name: 'painassesment_registrierung',
    status: 'active',
    item: [
        {
            type: 'choice',
            linkId: 'gender',
            prefix: '1',
            required: true,
            text: 'Wie ist Ihr biologisches Geschlecht?',
            disabledDisplay: 'hidden',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Weiblich',
                        system: 'http://uk-erlangen.de/painassesment/valueCoding/Gender_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Männlich',
                        system: 'http://uk-erlangen.de/painassesment/valueCoding/Gender_de'
                    }
                }
            ]
        },
        {
            type: 'open-choice',
            linkId: 'consent',
            prefix: '2',
            required: true,
            text: 'Sind Sie mit den Bedingungen der Studie und dem Datentransfer einverstanden?',
            disabledDisplay: 'hidden',
            enableWhen: [
                {
                    question: 'gender',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display:
                            'Ich habe die Einverständiserklärung gelesen und bin mit den Bedingungen einverstanden.',
                        system: 'http://uk-erlangen.de/painassesment/valueCoding/Consent_de'
                    }
                }
            ]
        }
    ]
};
