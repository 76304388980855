import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const DorAdoDurchfall: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/DorAdoDurchfall',
    name: 'DorAdo_durchfall',
    title: 'Durchfall',
    status: 'active',
    item: [
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'info_1',
            prefix: '0_1',
            required: true,
            text: 'Während einer Krebstherapie können Patienten und Patientinnen verschiedene Symptome und Nebenwirkungen erfahren. Bitte beantworten Sie die folgenden Fragen, indem Sie die Antwort auswählen, die Ihre Erfahrung in den letzten sieben Tagen am besten beschreibt ...'
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'header_1',
            prefix: '0_2',
            required: true,
            text: 'DURCHFALL'
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'ctcae_3a',
            prefix: '1',
            required: true,
            text: 'Während der letzten 7 Tage: Wie HÄUFIG hatten Sie DURCHFALL?',
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Nie',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Selten',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Gelegentlich',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Häufig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Fast immer',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                }
            ]
        }
    ]
};
