import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const DorAdoSonstige: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/DorAdoSonstige',
    name: 'DorAdo_sonstige',
    title: 'Sonstige',
    status: 'active',
    item: [
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'info_1',
            prefix: '0_1',
            required: true,
            text: 'Während einer Krebstherapie können Patienten und Patientinnen verschiedene Symptome und Nebenwirkungen erfahren. Bitte beantworten Sie die folgenden Fragen, indem Sie die Antwort auswählen, die Ihre Erfahrung in den letzten sieben Tagen am besten beschreibt ...'
        },
        {
            type: 'choice',
            disabledDisplay: 'hidden',
            linkId: 'ctcae_99a',
            prefix: '1',
            required: true,
            text: 'Haben Sie weitere Symptome, die Sie benennen möchten?',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ja',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                    }
                },
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Nein',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                    }
                }
            ]
        },
        {
            type: 'string',
            disabledDisplay: 'hidden',
            linkId: 'ctcae_99b',
            prefix: '2',
            required: true,
            text: 'Bitte nennen Sie das erste weiterere Syptome:',
            enableWhen: [
                {
                    question: 'ctcae_99a',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'hidden',
            linkId: 'ctcae_99c',
            prefix: '3',
            required: true,
            text: 'Während der letzten 7 Tage:  Wie STARK hatten Sie das Symptom im SCHLIMMSTEN FALL?',
            enableWhen: [
                {
                    question: 'ctcae_99a',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Gar nicht',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ein wenig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Mäßig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Ziemlich',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Sehr',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                }
            ]
        },
        {
            type: 'string',
            disabledDisplay: 'hidden',
            linkId: 'ctcae_99d',
            prefix: '4',
            required: false,
            text: 'Bitte nennen Sie ggf. das zweite weiterere Syptome:',
            enableWhen: [
                {
                    question: 'ctcae_99a',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'hidden',
            linkId: 'ctcae_99e',
            prefix: '5',
            required: false,
            text: 'Während der letzten 7 Tage:  Wie STARK hatten Sie das Symptom im SCHLIMMSTEN FALL?',
            enableWhen: [
                {
                    question: 'ctcae_99a',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Gar nicht',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ein wenig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Mäßig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Ziemlich',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Sehr',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                }
            ]
        },
        {
            type: 'string',
            disabledDisplay: 'hidden',
            linkId: 'ctcae_99f',
            prefix: '6',
            required: false,
            text: 'Bitte nennen Sie ggf. das dritte weiterere Syptome:',
            enableWhen: [
                {
                    question: 'ctcae_99a',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'hidden',
            linkId: 'ctcae_99g',
            prefix: '7',
            required: false,
            text: 'Während der letzten 7 Tage:  Wie STARK hatten Sie das Symptom im SCHLIMMSTEN FALL?',
            enableWhen: [
                {
                    question: 'ctcae_99a',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Gar nicht',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ein wenig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Mäßig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Ziemlich',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Sehr',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                }
            ]
        }
    ]
};
