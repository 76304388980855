import type {OneIdObjectTypes, Person} from '@refinio/one.core/lib/recipes.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import {Model} from '@refinio/one.models/lib/models/Model.js';
import ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import {objectEvents} from '@refinio/one.models/lib/misc/ObjectEventDispatcher.js';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile.js';
import type {Signature} from '@refinio/one.models/lib/recipes/SignatureRecipes.js';
import {
    getObject,
    type UnversionedObjectResult
} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import type {AffirmationCertificate} from '@refinio/one.models/lib/recipes/Certificates/AffirmationCertificate.js';
import {OEvent} from '@refinio/one.models/lib/misc/OEvent.js';
import {SET_ACCESS_MODE} from '@refinio/one.core/lib/storage-base-common.js';
import {createAccess} from '@refinio/one.core/lib/access.js';
import type {CertificateData} from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';
import type GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel.js';
import type {OneInstanceEndpoint} from '@refinio/one.models/lib/recipes/Leute/CommunicationEndpoints.js';
import {createMessageBus} from '@refinio/one.core/lib/message-bus.js';

import type AdminModel from './AdminModel.js';
import {getGroup, getPersonIdsForRole} from './utils.js';

const MessageBus = createMessageBus('ClinicModels');

interface IdObjectType {
    type: 'IdObject';
    hash: SHA256IdHash;
    obj: OneIdObjectTypes;
}

export default class ClinicModel extends Model {
    static readonly CLINICS_GROUP_NAME = 'gaiaClinics';

    private adminModel: AdminModel;
    private leuteModel: LeuteModel;
    private clinicOrganisationName: string;
    private processNewClinicDisconnectListeners: Array<() => void>;
    private clinicDataSharingDisconnectListener: (() => void) | undefined;
    private waitForAdminDisconnectListener: (() => void) | undefined;

    public onClinicsChange = new OEvent<() => void | Promise<void>>();

    constructor(adminModel: AdminModel, leuteModel: LeuteModel, clinicOrganisationName: string) {
        super();
        this.leuteModel = leuteModel;
        this.clinicOrganisationName = clinicOrganisationName;
        this.adminModel = adminModel;
        this.processNewClinicDisconnectListeners = [];
    }

    public init(): void {
        this.waitForAdminDisconnectListener = this.adminModel.onAdminChange(async () => {
            MessageBus.send('debug', 'ClinicModel - onAdminChange listener - start');

            // sync clinics group
            await this.addToClinicsGroup(
                await getPersonIdsForRole(this.leuteModel, this.isClinic.bind(this))
            );

            await this.shareClinicsWithCurrentInstances();
            this.shareClinicsWithNewInstances();
            this.processNewClinics();
        });
    }

    // required by super
    // eslint-disable-next-line @typescript-eslint/require-await
    public async shutdown(): Promise<void> {
        for (const processNewClinicDisconnectListener of this.processNewClinicDisconnectListeners) {
            processNewClinicDisconnectListener();
        }
        this.processNewClinicDisconnectListeners = [];

        if (this.waitForAdminDisconnectListener !== undefined) {
            this.waitForAdminDisconnectListener();
        }
        this.waitForAdminDisconnectListener = undefined;

        if (this.clinicDataSharingDisconnectListener !== undefined) {
            this.clinicDataSharingDisconnectListener();
        }
        this.clinicDataSharingDisconnectListener = undefined;
    }

    public async hasProfileWithOrganisationNameTrustedByRootOfTrust(
        personId?: SHA256IdHash<Person>
    ): Promise<boolean> {
        const profiles = await this.getProfilesWithOrganisationName(personId);

        for (const profile of profiles) {
            if (profile.loadedVersion === undefined) {
                // if this happens in this case the models are broken, so it is a typescript check
                continue;
            }

            if (await this.adminModel.isProfileTrusted(profile.loadedVersion)) {
                return true;
            }
        }

        return false;
    }

    public isProfileWithOrganisationName(profile: ProfileModel): boolean {
        if (profile.loadedVersion === undefined) {
            return false;
        }

        return profile
            .descriptionsOfType('OrganisationName')
            .some(on => on.name === this.clinicOrganisationName);
    }

    public async hasProfileWithOrganisationName(personId?: SHA256IdHash<Person>): Promise<boolean> {
        const targetPersonId = personId ?? (await this.leuteModel.myMainIdentity());
        const someone = await this.leuteModel.getSomeone(targetPersonId);

        if (someone === undefined) {
            return false;
        }

        for (const profile of await someone.profiles(targetPersonId)) {
            if (this.isProfileWithOrganisationName(profile)) {
                return true;
            }
        }

        return false;
    }

    public async canCreatePhysician(trustObject: IdObjectType): Promise<boolean> {
        if (
            trustObject.obj.$type$ === 'Person' &&
            !(await this.isClinic(trustObject.hash as SHA256IdHash<Person>)) &&
            !(await this.adminModel.isAdmin(trustObject.hash as SHA256IdHash<Person>))
        ) {
            return this.isClinic();
        }

        return false;
    }

    public async isClinic(personId?: SHA256IdHash<Person>): Promise<boolean> {
        return this.hasProfileWithOrganisationNameTrustedByRootOfTrust(
            personId ?? (await this.leuteModel.myMainIdentity())
        );
    }

    public async getClinicsGroup(): Promise<GroupModel> {
        return getGroup(ClinicModel.CLINICS_GROUP_NAME);
    }

    /** ***** Private ***** **/

    private async shareClinicsWithCurrentInstances(): Promise<void> {
        const others = await this.leuteModel.others();
        const clinicsGroup = await this.getClinicsGroup();
        const adminPersonId = this.adminModel.getAdminPersonId();

        if (adminPersonId === undefined) {
            throw new Error('ClinicModel - share - can not find admin person id');
        }

        for (const clinicId of clinicsGroup.persons) {
            const profiles = await this.getProfilesWithOrganisationName(clinicId);
            const trustedClinicProfiles: ProfileModel[] = [];

            for (const profile of profiles) {
                if (profile.loadedVersion === undefined) {
                    // if this happens in this case the models are broken, so it is a typescript check
                    continue;
                }

                if (await this.adminModel.isProfileTrusted(profile.loadedVersion)) {
                    trustedClinicProfiles.push(profile);
                }
            }

            for (const trustedClinicProfile of trustedClinicProfiles) {
                if (trustedClinicProfile.loadedVersion === undefined) {
                    throw new Error(
                        `Profile of person id ${clinicId} profile id ${trustedClinicProfile.idHash} has no loaded version`
                    );
                }

                const clinicAffirmationCertificates =
                    await this.leuteModel.trust.getCertificatesOfType(
                        trustedClinicProfile.loadedVersion,
                        'AffirmationCertificate'
                    );

                let sharedCert: CertificateData<AffirmationCertificate> | undefined = undefined;

                for (const clinicAffirmationCertificate of clinicAffirmationCertificates) {
                    if (
                        await this.leuteModel.trust.isSignedBy(
                            clinicAffirmationCertificate.certificateHash,
                            adminPersonId
                        )
                    ) {
                        sharedCert = clinicAffirmationCertificate;
                    }
                }

                if (sharedCert === undefined) {
                    throw new Error(
                        'Profile of Clinic does not contain an admin signed affirmation certificate'
                    );
                }

                const persons: SHA256IdHash<Person>[] = [];

                for (const other of others) {
                    for (const personId of other.identities()) {
                        if (clinicsGroup.persons.includes(personId)) {
                            continue;
                        }

                        persons.push(personId);
                    }
                }

                if (persons.length === 0) {
                    return;
                }

                // share clinic profile
                await createAccess([
                    {
                        id: trustedClinicProfile.idHash,
                        person: persons,
                        group: [],
                        mode: SET_ACCESS_MODE.ADD
                    }
                ]);

                // share clinic certificate
                await createAccess([
                    {
                        object: sharedCert.certificateHash,
                        person: persons,
                        group: [],
                        mode: SET_ACCESS_MODE.ADD
                    }
                ]);

                await createAccess([
                    {
                        object: sharedCert.signatureHash,
                        person: persons,
                        group: [],
                        mode: SET_ACCESS_MODE.ADD
                    }
                ]);
            }
        }
    }

    private shareClinicsWithNewInstances(): void {
        if (this.clinicDataSharingDisconnectListener !== undefined) {
            return;
        }

        MessageBus.send('debug', 'ClinicModel - shareClinicsWithNewInstances - init');
        this.clinicDataSharingDisconnectListener = this.leuteModel.onNewOneInstanceEndpoint(i => {
            MessageBus.send(
                'debug',
                'ClinicModel - shareClinicsWithNewInstances - share clinics with instance',
                i
            );
            return this.shareClinicsWithCurrentInstances();
        });
    }

    private processNewClinics(): void {
        if (this.processNewClinicDisconnectListeners.length > 0) {
            return;
        }
        MessageBus.send('debug', 'ClinicModel - processNewClinics - init');

        // check new AffirmationCertificate objects
        this.processNewClinicDisconnectListeners.push(
            objectEvents.onUnversionedObject(
                this.checkForClinicSignature.bind(this),
                'ClinicModel: process new clinic',
                'Signature'
            )
        );

        this.processNewClinicDisconnectListeners.push(
            this.leuteModel.onNewOneInstanceEndpoint(async (endpoint: OneInstanceEndpoint) => {
                if (!(await this.isClinic(endpoint.personId))) {
                    return;
                }

                await this.addToClinicsGroup([endpoint.personId]);
                return this.shareClinicsWithCurrentInstances();
            })
        );
    }

    private async getProfilesWithOrganisationName(
        personId?: SHA256IdHash<Person>
    ): Promise<ProfileModel[]> {
        const targetPersonId = personId ? personId : await this.leuteModel.myMainIdentity();
        const profiles: ProfileModel[] = [];

        const someone = await this.leuteModel.getSomeone(targetPersonId);

        if (someone === undefined) {
            // profile has not arived yet
            return profiles;
        }

        for (const profile of await someone.profiles(targetPersonId)) {
            if (this.isProfileWithOrganisationName(profile)) {
                profiles.push(profile);
            }
        }

        return profiles;
    }

    private async getTrustedProfileFromSignature(
        signature: Signature,
        affirmationCertificate?: AffirmationCertificate
    ): Promise<ProfileModel | undefined> {
        const adminPersonId = this.adminModel.getAdminPersonId();

        if (adminPersonId === undefined) {
            // no admin -> no trusted profiles from admin
            return;
        }

        const affirmationCertificateObj =
            affirmationCertificate ?? (await getObject(signature.data));

        if (affirmationCertificateObj.$type$ !== 'AffirmationCertificate') {
            return;
        }

        if (signature.issuer !== adminPersonId) {
            // not signed by admin -> no trusted profiles
            return;
        }

        const dataHash = affirmationCertificateObj.data;

        if ((await getObject(dataHash)).$type$ !== 'Profile') {
            return;
        }

        const profileModel = await ProfileModel.constructFromVersion(
            dataHash as SHA256Hash<Profile>
        );

        return profileModel;
    }

    async addToClinicsGroup(personIds: Array<SHA256IdHash<Person>>): Promise<boolean> {
        const clinicsGroup = await this.getClinicsGroup();
        const me = await this.leuteModel.me();
        const myIndentitis = me.identities();
        let addedNew = false;
        let addedNewTrust = false;

        for (const personId of personIds) {
            if (clinicsGroup.persons.includes(personId)) {
                continue;
            }

            if (!myIndentitis.includes(personId)) {
                const someone = await this.leuteModel.getSomeone(personId);

                if (someone === undefined) {
                    throw new Error(`Could not find someone with personId ${personId}`);
                }

                for (const profile of await someone.profiles()) {
                    if (profile.loadedVersion === undefined) {
                        // typescript lint avoidance, should not be here
                        throw new Error(
                            `Profile id does not have a loaded version ${profile.idHash}`
                        );
                    }

                    addedNewTrust = true;
                    await this.leuteModel.trust.certify('TrustKeysCertificate', {
                        profile: profile.loadedVersion
                    });
                }
            }

            MessageBus.send('debug', 'ClinicModel - addToClinicsGroup', personId);
            clinicsGroup.persons.push(personId);
            addedNew = true;
        }

        if (addedNewTrust) {
            await this.leuteModel.trust.refreshCaches(); // Just a hack until we have a better way of refresh
        }

        if (addedNew) {
            MessageBus.send('debug', 'ClinicModel - addToClinicsGroup - emit');
            await clinicsGroup.saveAndLoad();
            this.onClinicsChange.emit();
        }

        return addedNew;
    }

    private async checkForClinicSignature(
        signatureResult: UnversionedObjectResult<Signature>
    ): Promise<void> {
        const affirmationCertificate = await getObject(signatureResult.obj.data);

        if (affirmationCertificate.$type$ !== 'AffirmationCertificate') {
            MessageBus.send(
                'debug',
                'ClinicModel - processNewClinics - signature listener - not affirmation cert',
                affirmationCertificate
            );
            return;
        }

        const certificates = await this.leuteModel.trust.getCertificates(
            affirmationCertificate.data
        );

        for (const certificate of certificates) {
            const affirmationCert = certificate.certificate;

            if (affirmationCert.$type$ === 'AffirmationCertificate') {
                try {
                    const profileModel = await this.getTrustedProfileFromSignature(
                        certificate.signature,
                        affirmationCert
                    );

                    if (profileModel === undefined) {
                        continue;
                    }

                    await this.addToClinicsGroup([profileModel.personId]);
                    return this.shareClinicsWithCurrentInstances();
                } catch (_e) {
                    // catches cases where we have not received clinic profile
                    // see other processNewClinicDisconnectListeners
                    // that handle the new profile case
                    MessageBus.send(
                        'debug',
                        'ClinicModel - checkForClinicSignature - no clinic someone (yet?)',
                        affirmationCert
                    );
                }
            }
        }
    }
}
