import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const DorAdoGelenkschmerzen: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/DorAdoGelenkschmerzen',
    name: 'DorAdo_gelenk',
    title: 'Gelenkschmerzen',
    status: 'active',
    item: [
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'info_1',
            prefix: '0_1',
            required: true,
            text: 'Während einer Krebstherapie können Patienten und Patientinnen verschiedene Symptome und Nebenwirkungen erfahren. Bitte beantworten Sie die folgenden Fragen, indem Sie die Antwort auswählen, die Ihre Erfahrung in den letzten sieben Tagen am besten beschreibt ...'
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'header_1',
            prefix: '0_2',
            required: true,
            text: 'GELENKSCHMERZEN (Z. B. ELLENBOGEN, KNIE, SCHULTERN)'
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'ctcae_4a',
            prefix: '1',
            required: true,
            text: 'Während der letzten 7 Tage: Wie HÄUFIG hatten Sie GELENKSCHMERZEN (Z. B. ELLENBOGEN, KNIE, SCHULTERN)?',
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Nie',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Selten',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Gelegentlich',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Häufig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Fast immer',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'ctcae_4b',
            prefix: '2',
            required: false,
            text: 'Während der letzten 7 Tage: Wie STARK hatten Sie GELENKSCHMERZEN (Z. B. ELLENBOGEN, KNIE, SCHULTERN) im SCHLIMMSTEN FALL?',
            enableWhen: [
                {
                    operator: '!=',
                    question: 'ctcae_4a',
                    answerCoding: {
                        code: '0'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Gar nicht',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ein wenig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Mäßig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Ziemlich',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Sehr',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'ctcae_4c',
            prefix: '3',
            required: false,
            text: 'Während der letzten 7 Tage: Wie sehr haben GELENKSCHMERZEN (Z. B. ELLENBOGEN, KNIE, SCHULTERN) Sie in Ihren täglichen Aktivitäten GESTÖRT?',
            enableWhen: [
                {
                    operator: '!=',
                    question: 'ctcae_4a',
                    answerCoding: {
                        code: '0'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Gar nicht',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ein wenig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Mäßig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Ziemlich',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Sehr',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                }
            ]
        }
    ]
};
