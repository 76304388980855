import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel.js';

export const GaiaRegistrierung: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/GaiaRegistrierung',
    name: 'gaia_registrierung',
    title: 'gaia.one Registrierung',
    status: 'active',
    item: [
        {
            linkId: 'ageinclusion',
            prefix: '1',
            type: 'choice',
            text: 'Sind Sie über 18 Jahre alt?',
            required: true,
            disabledDisplay: 'hidden',
            answerOption: [
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ja',
                        system: 'http://uk-erlangen.de/gaia/valueCoding/YesNo_de'
                    }
                },
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Nein',
                        system: 'http://uk-erlangen.de/gaia/valueCoding/YesNo_de'
                    }
                }
            ]
        },
        {
            linkId: 'birthyear',
            prefix: '2',
            type: 'integer',
            text: 'In welchem Jahr sind Sie geboren?',
            required: true,
            disabledDisplay: 'hidden',
            extension: [
                {
                    url: 'http://hl7.org/fhir/StructureDefinition/maxValue',
                    valueInteger: 2022
                },
                {
                    url: 'http://hl7.org/fhir/StructureDefinition/minValue',
                    valueInteger: 1920
                }
            ]
        }
    ]
};
