import type {ReactElement, ReactNode} from 'react';

import './RegistrationContentBubble.css';

export default function RegistrationContentBubble(props: {
    children: ReactNode;
    className?: string;
}): ReactElement {
    return (
        <div className="content-bubble-container">
            <div className={`content-bubble${props.className ? ` ${props.className}` : ''}`}>
                {props.children}
            </div>
        </div>
    );
}
