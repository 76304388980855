import type {ReactElement} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';
import FormControl from '@mui/material/FormControl/FormControl.js';
import TextField from '@mui/material/TextField/TextField.js';
import Typography from '@mui/material/Typography/Typography.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';

const minPasswordLength = 4;
const maxPasswordLength = 32;
// const simplePasswordValidationRegex = new RegExp(/^[A-Za-z0-9!#%]{4,32}$/);

/**
 * @param props.onNext callback
 * @param props.password Optional. Default ''. the content of the input field
 * @param props.headerAddon Optional.
 * @returns
 */
export default function RegisterPassword(props: {
    onNext: (password: string) => void;
    password?: string;
    headerAddon?: ReactElement;
}): ReactElement {
    const i18n = useTranslation();
    const [password, setPassword] = useState<string>(props.password ? props.password : '');
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>(
        props.password === undefined ? '' : props.password
    );
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    function validate() {
        if (password === '' || passwordConfirmation === '') {
            setNotificationMessage('gaia_registration.register.password.error.mustFillFields');
            setNotificationType(NOTIFICATION.Error);
        } else if (password !== passwordConfirmation) {
            setNotificationMessage('gaia_registration.register.password.error.passwordsMustMatch');
            setNotificationType(NOTIFICATION.Error);
            /*
             * Commented out because we accept every character
             *
             *  } else if (!simplePasswordValidationRegex.test(password) {
             *    setNotificationMessage('gaia_registration.register.password.error.passwordInvalid');
             *    setNotificationType(NOTIFICATION.Error);
             *
             * */
        } else if (password.length < minPasswordLength) {
            setNotificationMessage('gaia_registration.register.password.error.passwordTooShort');
            setNotificationType(NOTIFICATION.Error);
        } else if (password.length > maxPasswordLength) {
            setNotificationMessage('gaia_registration.register.password.error.passwordTooLong');
            setNotificationType(NOTIFICATION.Error);
        } else {
            props.onNext(password);
        }
    }

    return (
        <>
            {props.headerAddon}
            <Typography className="register-header" component="h3">
                {i18n.t('gaia_registration.register.header')}
            </Typography>
            <div className="register-content-container">
                <div className="register-content space">
                    <FormControl className="field">
                        <Typography className="register-field-label" component="div">
                            {i18n.t('gaia_registration.register.password.label.password')}:
                        </Typography>
                        <TextField
                            type="password"
                            className="input"
                            variant="outlined"
                            placeholder="************"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    validate();
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl className="field">
                        <Typography className="register-field-label" component="div">
                            {i18n.t(
                                'gaia_registration.register.password.label.passwordConfirmation'
                            )}
                            :
                        </Typography>
                        <TextField
                            type="password"
                            className="input"
                            variant="outlined"
                            placeholder="************"
                            value={passwordConfirmation}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    validate();
                                }
                            }}
                            onChange={e => setPasswordConfirmation(e.target.value)}
                        />
                    </FormControl>
                </div>
            </div>
            <div className="register-buttons">
                <Button className="finish" onClick={validate}>
                    {i18n.t('gaia_registration.buttons.common.finish')}
                </Button>
            </div>
        </>
    );
}
