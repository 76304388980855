import type {ReactElement} from 'react';

import './StageIndicator.css';

/**
 * 0 < props.stage <= props.max
 * @param props.stage Current stage indicator.
 * @param props.max
 * @returns
 */
export default function StageIndicator(props: {stage: number; max: number}): ReactElement {
    return (
        <div className="stage-indicator-container">
            {[...Array(props.max).keys()].map(index => (
                <div
                    key={index}
                    className={`stage${props.stage >= index + 1 ? ' completed' : ''}`}
                    style={{
                        width: `${100 / props.max}%`
                    }}
                >
                    <div className="stage-indicator" />
                </div>
            ))}
        </div>
    );
}
