import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const DorAdoFACTB: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/FACT-B',
    name: 'fact_b',
    title: 'FACT-B',
    status: 'active',
    item: [
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'header1',
            prefix: '0_0',
            required: true,
            text: 'FACT-B Fragebogen'
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'info1',
            prefix: '0_1',
            required: true,
            text: 'Nachfolgend finden Sie eine Liste von Aussagen, die von anderen Personen mit Ihrer Krankheit für wichtig befunden wurden. Bitte geben Sie jeweils an, wie sehr jede der folgenden Aussagen im Laufe der letzten 7 Tage auf Sie zugetroffen hat, indem Sie die entsprechende Zahl ankreuzen.'
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'fact_gp',
            prefix: '1',
            required: true,
            text: 'KÖRPERLICHES WOHLBEFINDEN',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gp1',
                    prefix: '1_1',
                    required: true,
                    text: 'Mir fehlt es an Energie',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gp2',
                    prefix: '1_2',
                    required: true,
                    text: 'Mir ist übel',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gp3',
                    prefix: '1_3',
                    required: true,
                    text: 'Wegen meiner körperlichen Verfassung habe ich Schwierigkeiten, den Bedürfnissen meiner Familie gerecht zu werden',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gp4',
                    prefix: '1_4',
                    required: true,
                    text: 'Ich habe Schmerzen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gp5',
                    prefix: '1_5',
                    required: true,
                    text: 'Die Nebenwirkungen der Behandlung machen mir zu schaffen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gp6',
                    prefix: '1_6',
                    required: true,
                    text: 'Ich fühle mich krank',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gp7',
                    prefix: '1_7',
                    required: true,
                    text: 'Ich muss zeitweilig im Bett bleiben',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'fact_gs',
            prefix: '2',
            required: true,
            text: 'VERHÄLTNIS ZU FREUNDEN, BEKANNTEN UND IHRER FAMILIE',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gs1',
                    prefix: '2_1',
                    required: true,
                    text: 'Ich stehe meinen Freunden nahe',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gs2',
                    prefix: '2_2',
                    required: true,
                    text: 'Ich bekomme seelische Unterstützung von meiner Familie',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gs3',
                    prefix: '2_3',
                    required: true,
                    text: 'Ich bekomme Unterstützung von meinen Freunden',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gs4',
                    prefix: '2_4',
                    required: true,
                    text: 'Meine Familie hat meine Krankheit akzeptiert',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gs5',
                    prefix: '2_5',
                    required: true,
                    text: 'Ich bin damit zufrieden, wie wir innerhalb meiner Familie über meine Krankheit reden',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gs6',
                    prefix: '2_6',
                    required: true,
                    text: 'Ich fühle mich meinem Partner/meiner Partnerin oder der Person, die mir am nächsten steht, eng verbunden',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'open-choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_q1',
                    prefix: '2_7',
                    required: false,
                    text: 'Beantworten Sie bitte die folgende Frage unabhängig davon, inwieweit Sie zurzeit sexuell aktiv sind.',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display:
                                    'Wenn Sie die Frage lieber nicht beantworten möchten, kreuzen Sie das nebenstehende Kästchen an und fahren Sie mit dem nächsten Abschnitt fort.',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/DoNotAnswer_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gs7',
                    prefix: '2_8',
                    required: false,
                    text: 'Ich bin mit meinem Sexualleben zufrieden',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'fact_ge',
            prefix: '3',
            required: true,
            text: 'SEELISCHES WOHLBEFINDEN',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_ge1',
                    prefix: '3_1',
                    required: true,
                    text: 'Ich bin traurig',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_ge2',
                    prefix: '3_2',
                    required: true,
                    text: 'Ich bin damit zufrieden, wie ich meine Krankheit bewältige',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_ge3',
                    prefix: '3_3',
                    required: true,
                    text: 'Ich verliere die Hoffnung im Kampf gegen meine Krankheit',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_ge4',
                    prefix: '3_4',
                    required: true,
                    text: 'Ich bin nervös',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_ge5',
                    prefix: '3_5',
                    required: true,
                    text: 'Ich mache mir Sorgen über den Tod',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_ge6',
                    prefix: '3_6',
                    required: true,
                    text: 'Ich mache mir Sorgen, dass sich mein Zustand verschlechtern wird',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'fact_gf',
            prefix: '4',
            required: true,
            text: 'FUNKTIONSFÄHIGKEIT',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gf1',
                    prefix: '4_1',
                    required: true,
                    text: 'Ich bin in der Lage zu arbeiten (einschließlich Arbeit zu Hause)',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gf2',
                    prefix: '4_2',
                    required: true,
                    text: 'Meine Arbeit (einschließlich Arbeit zu Hause) füllt mich aus',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gf3',
                    prefix: '4_3',
                    required: true,
                    text: 'Ich kann mein Leben genießen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gf4',
                    prefix: '4_4',
                    required: true,
                    text: 'Ich habe mich mit meiner Krankheit abgefunden',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gf5',
                    prefix: '4_5',
                    required: true,
                    text: 'Ich schlafe gut',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gf6',
                    prefix: '4_6',
                    required: true,
                    text: 'Ich kann meine Freizeit genießen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_gf7',
                    prefix: '4_7',
                    required: true,
                    text: 'Ich bin derzeit mit meinem Leben zufrieden',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'fact_b',
            prefix: '5',
            required: true,
            text: 'ZUSÄTZLICHE FAKTOREN',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_b1',
                    prefix: '5_1',
                    required: true,
                    text: 'Ich leide unter Atemnot',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_b2',
                    prefix: '5_2',
                    required: true,
                    text: 'Die Wahl meiner Kleidung ist für mich eine Belastung',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_b3',
                    prefix: '5_3',
                    required: true,
                    text: 'Einer meiner Arme, oder beide, sind geschwollen oder empfindlich',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_b4',
                    prefix: '5_4',
                    required: true,
                    text: 'Ich fühle mich körperlich anziehend',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_b5',
                    prefix: '5_5',
                    required: true,
                    text: 'Haarausfall macht mir zu schaffen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_b6',
                    prefix: '5_6',
                    required: true,
                    text: 'Ich mache mir Sorgen, dass auch andere Familienmitglieder eines Tages dieselbe Krankheit wie ich bekommen könnten',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_b7',
                    prefix: '5_7',
                    required: true,
                    text: 'Ich mache mir Gedanken darüber, welchen Einfluss Stress auf meine Krankheit hat',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_b8',
                    prefix: '5_8',
                    required: true,
                    text: 'Meine Gewichtsveränderung macht mir zu schaffen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_b9',
                    prefix: '5_9',
                    required: true,
                    text: 'Ich fühle mich als Frau',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'fact_p2',
                    prefix: '5_10',
                    required: true,
                    text: 'In einigen Körperbereichen habe ich Schmerzen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Überhaupt nicht',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ein wenig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '2',
                                version: '1.0',
                                display: 'Mäßig',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '3',
                                version: '1.0',
                                display: 'Ziemlich',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '4',
                                version: '1.0',
                                display: 'Sehr',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/NotAAll_VeryMuch_de'
                            }
                        }
                    ]
                }
            ]
        }
    ]
};
