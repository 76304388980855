import type {Dispatch, SetStateAction} from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import i18n from '@/i18n.js';
import type {ProfileDetails} from '@/root/profile/common/types.js';
import {
    loadProfileCommunicationEndpoints,
    loadProfilePersonDescription
} from '@/hooks/contact/utils.js';

/**
 * Used to get the profiles details whenever the profile is updated.
 * @param profileModel
 * @param leuteModel
 */
export function useProfileDetails(
    profileModel: ProfileModel | undefined,
    leuteModel?: LeuteModel | undefined
): [ProfileDetails, Dispatch<SetStateAction<ProfileDetails>>] {
    const [profileDetails, setProfileDetails] = useState<ProfileDetails>({
        name: '',
        personEmail: '',
        avatar: undefined
    });
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const navigate = useNavigate();

    useEffect(() => {
        let cancelled = false;

        async function updateSomeoneProfile() {
            if (profileModel === undefined) {
                return;
            }
            await profileModel.loadLatestVersion();
            const descriptions = await loadProfilePersonDescription(profileModel);
            const descriptionsWithCommunicationEndpoints = await loadProfileCommunicationEndpoints(
                descriptions,
                profileModel
            );

            const details = {
                owner: profileModel.owner,
                personId: profileModel.personId,
                ...descriptionsWithCommunicationEndpoints,
                organisationName: descriptions.organisationName
            };

            if (details.owner && leuteModel) {
                details.ownerName = leuteModel.getPersonName(details.owner);
            }

            if (!cancelled) {
                setProfileDetails(details);
            }
        }

        updateSomeoneProfile().catch(_ => {
            setNotificationMessage(i18n.t('errors.someone.profile'));
            setNotificationType(NOTIFICATION.Error);
            navigate('/', {replace: true});
        });

        const disconnect =
            profileModel === undefined ? () => {} : profileModel.onUpdate(updateSomeoneProfile);

        return () => {
            disconnect();
            cancelled = true;
        };
    }, [profileModel, leuteModel, navigate, setNotificationType, setNotificationMessage]);

    return [profileDetails, setProfileDetails];
}
