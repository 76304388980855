import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const DorAdoMudigkeit: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/DorAdoMuedigkeit',
    name: 'DorAdo_muedigkeit',
    title: 'Müdigkeit',
    status: 'active',
    item: [
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'info_1',
            prefix: '0_1',
            required: true,
            text: 'Während einer Krebstherapie können Patienten und Patientinnen verschiedene Symptome und Nebenwirkungen erfahren. Bitte beantworten Sie die folgenden Fragen, indem Sie die Antwort auswählen, die Ihre Erfahrung in den letzten sieben Tagen am besten beschreibt ...'
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'header_1',
            prefix: '0_2',
            required: true,
            text: 'MÜDIGKEIT, ERSCHÖPFUNG ODER FEHLENDE ENERGIE'
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'ctcae_5a',
            prefix: '1',
            required: true,
            text: 'Während der letzten 7 Tage: Wie STARK waren Ihre MÜDIGKEIT, ERSCHÖPFUNG ODER FEHLENDE ENERGIE im SCHLIMMSTEN FALL?',
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Gar nicht',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ein wenig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Mäßig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Ziemlich',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Sehr',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'ctcae_5b',
            prefix: '2',
            required: true,
            text: 'Während der letzten 7 Tage: Wie sehr haben MÜDIGKEIT, ERSCHÖPFUNG ODER FEHLENDE ENERGIE Sie in Ihren täglichen Aktivitäten GESTÖRT?',
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Gar nicht',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ein wenig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Mäßig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Ziemlich',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Sehr',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                }
            ]
        }
    ]
};
