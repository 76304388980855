import type {ReactElement} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';

import ProfileChange from '@/root/profile/change/ProfileChange.js';
import ProfileCreate from '@/root/profile/create/ProfileCreate.js';
import SomeoneProfileStatus, {
    STATUS_INFORMATION
} from '@/root/profile/profileId/status/create/SomeoneProfileStatus.js';

import PageNotFound from '@/components/PageNotFound.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import EditProfile from './profileId/edit/EditProfile.js';
import ProfileView from './profileId/ProfileView.js';

export default function ProfileRouter(props: {
    topicModel: TopicModel;
    leuteModel: LeuteModel;
    getTrustView?: (hashes: (SHA256Hash | SHA256IdHash)[]) => ReactElement;
}): ReactElement {
    const {topicModel, leuteModel} = props;
    const i18n = useTranslation();
    const goBack = useNavigateBack();

    return (
        <Routes>
            <Route
                path={':profileId'}
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.CHEVRON_VIEW}
                            title={i18n.t('leute.profile.title')}
                            leftBtnCallback={goBack}
                        />
                        <ProfileView
                            topicModel={topicModel}
                            leuteModel={leuteModel}
                            getInformationAddon={props.getTrustView}
                        />
                    </>
                }
            />
            <Route path={':profileId/edit'} element={<EditProfile />} />
            <Route
                path={':profileId/status/text'}
                element={
                    <SomeoneProfileStatus
                        leuteModel={leuteModel}
                        statusInformation={[STATUS_INFORMATION.LOCATION, STATUS_INFORMATION.TEXT]}
                    />
                }
            />
            <Route
                path={':profileId/status/image'}
                element={
                    <>
                        <SomeoneProfileStatus
                            leuteModel={leuteModel}
                            statusInformation={[
                                STATUS_INFORMATION.LOCATION,
                                STATUS_INFORMATION.IMAGE
                            ]}
                        />
                    </>
                }
            />
            <Route
                path="create/:someoneId"
                element={
                    <ProfileCreate
                        leuteModel={leuteModel}
                        doneRoute="/profile/:profileId"
                        cancelRoute={-1}
                    />
                }
            />
            <Route
                path="change/:someoneId"
                element={
                    <>
                        <ProfileChange
                            topicModel={topicModel}
                            leuteModel={leuteModel}
                            doneRoute="/someone/:someoneId"
                            cancelRoute={-1}
                        />
                    </>
                }
            />
            <Route element={<PageNotFound />} />
        </Routes>
    );
}
