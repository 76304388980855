import type {NavigationDrawerMenuItem} from '@refinio/one.ui/lib/ui/components/menu/NavigationDrawer.js';

import i18n from '@/i18n.js';

/**
 * Returns the list of the menu items
 */
export function getGaiaMenuItems(): NavigationDrawerMenuItem[] {
    return [
        {
            name: i18n.t('menu.home'),
            path: '/home'
        },
        {
            name: i18n.t('menu.contacts'),
            path: '/contacts'
        },
        {
            name: i18n.t('menu.patients'),
            path: '/patients'
        },
        {
            name: i18n.t('menu.questionnaires.title'),
            subItems: [
                {
                    name: i18n.t('menu.questionnaires.example'),
                    path: '/questionnaire/new?questionnaires=Example%20Questionnaire'
                },
                {
                    name: i18n.t('menu.questionnaires.onBoarding'),
                    path: '/questionnaire/new?questionnaires=onboarding_patient'
                },
                {
                    name: i18n.t('menu.questionnaires.generalQuestionnaireShort'),
                    path: '/questionnaire/new?questionnaires=General%20Questionnaire%20%28short%29'
                },
                {
                    name: i18n.t('menu.questionnaires.whpQuestionnaire'),
                    path: '/questionnaire/new?questionnaires=WHP%20Follow%20Up%20Visit%20From%201'
                },
                {
                    name: i18n.t('menu.questionnaires.CervicalCancerScreeningAndTreatmentRegister'),
                    path: '/questionnaire/new?questionnaires=cervical_cancer_screeninc_and_treatment_register'
                },
                {
                    name: i18n.t('menu.questionnaires.resclue'),
                    path: '/questionnaire/new?questionnaires=resclue'
                },
                {
                    name: i18n.t('menu.questionnaires.ifcpc'),
                    path: '/questionnaire/new?questionnaires=2011_IFCPC_colposcopic_terminology_cervix'
                },
                {
                    name: i18n.t('menu.questionnaires.colposcopy_exam_record'),
                    path: '/questionnaire/new?questionnaires=colposcopy_examination_record'
                },
                {
                    name: i18n.t('menu.questionnaires.generalFeedback'),
                    path: '/questionnaire/new?questionnaires=general_feedback_edda'
                },
                {
                    name: i18n.t('menu.questionnaires.specificFeedback'),
                    path: '/questionnaire/new?questionnaires=specific_feedback_edda'
                }
            ]
        },
        {
            name: i18n.t('menu.ecpire'),
            path: '/ecpire'
        },
        {
            name: i18n.t('menu.journal'),
            path: '/journal'
        },
        {
            name: i18n.t('menu.calendar'),
            path: '/calendar'
        },
        {
            name: i18n.t('menu.connections'),
            path: '/connections'
        },
        {
            name: i18n.t('menu.settings'),
            path: '/settings'
        },
        {
            name: i18n.t('menu.iom_view'),
            path: '/iom'
        },
        {
            name: i18n.t('menu.downloads'),
            path: '/downloads'
        },
        {
            name: i18n.t('malawi_demo.aboutMenu'),
            subItems: [
                {
                    name: i18n.t('malawi_demo.imprintMenu'),
                    path: '/about/imprint'
                },
                {
                    name: i18n.t('malawi_demo.privacyPolicyMenu'),
                    path: '/about/privacy-policy'
                }
            ]
        }
    ];
}
