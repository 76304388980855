import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const DorAdoDistressThermometer: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/NCCNDistress-Thermometer',
    name: 'nccn_distress_thermometer',
    title: 'NCCN Distress-Thermometer',
    status: 'active',
    item: [
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'header_1',
            prefix: '0_0',
            required: true,
            text: 'NCCN-THERMOMETER DER STRESSOREN'
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'info_1',
            prefix: '0_1',
            required: true,
            text: 'Stress kann sich auf geistiger, körperlicher, sozialer oder spiritueller Ebene als unangenehme Erfahrung äußern. Er kann die Art und Weise beeinflussen, wie Sie denken, fühlen oder handeln. Stress kann es erschweren, mit der Krebserkrankung, ihren Symptomen oder ihrer Behandlung zurechtzukommen.'
        },
        {
            type: 'slider',
            disabledDisplay: 'protected',
            linkId: 'NCNNthermo',
            prefix: '0_2',
            required: true,
            text: 'Bitte kreisen Sie die Zahl ein (0 = Kein Stress; 10 = Extremer Stress), die am besten beschreibt, wie belastet Sie sich in der letzten Woche einschließlich heute gefühlt haben.',
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: '0',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/0_10_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: '1',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/0_10_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: '2',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/0_10_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: '3',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/0_10_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: '4',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/0_10_de'
                    }
                },
                {
                    valueCoding: {
                        code: '5',
                        version: '1.0',
                        display: '5',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/0_10_de'
                    }
                },
                {
                    valueCoding: {
                        code: '6',
                        version: '1.0',
                        display: '6',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/0_10_de'
                    }
                },
                {
                    valueCoding: {
                        code: '7',
                        version: '1.0',
                        display: '7',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/0_10_de'
                    }
                },
                {
                    valueCoding: {
                        code: '8',
                        version: '1.0',
                        display: '8',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/0_10_de'
                    }
                },
                {
                    valueCoding: {
                        code: '9',
                        version: '1.0',
                        display: '9',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/0_10_de'
                    }
                },
                {
                    valueCoding: {
                        code: '10',
                        version: '1.0',
                        display: '10',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/0_10_de'
                    }
                }
            ]
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'header_2',
            prefix: '0_3',
            required: true,
            text: 'LISTE DER PROBLEME'
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'intro_1',
            prefix: '0_4',
            required: true,
            text: 'Hatten Sie in der letzten Woche, einschließlich des heutigen Tages, Beschwerden hinsichtlich eines der unten aufgeführten Punkte?'
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'NCNN_1',
            prefix: '1',
            required: true,
            text: 'Körperliches Beschwerden',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNpain',
                    prefix: '1_1',
                    required: true,
                    text: 'Schmerzen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNsleep',
                    prefix: '1_2',
                    required: true,
                    text: 'Schlaf',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNfatigue',
                    prefix: '1_3',
                    required: true,
                    text: 'Müdigkeit',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNtobacco',
                    prefix: '1_4',
                    required: true,
                    text: 'Tabakkonsum',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNdrugs',
                    prefix: '1_5',
                    required: true,
                    text: 'Substanzgebrauch',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNmemory',
                    prefix: '1_6',
                    required: true,
                    text: 'Gedächtnis oder Konzentration',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNsex',
                    prefix: '1_7',
                    required: true,
                    text: 'Sexuelle Gesundheit',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNeating',
                    prefix: '1_8',
                    required: true,
                    text: 'Veränderungen im Essverhalten',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNabilities',
                    prefix: '1_9',
                    required: true,
                    text: 'Verlust oder Veränderung der körperlichen Fähigkeiten',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'NCNN_2',
            prefix: '2',
            required: true,
            text: 'Emotionale Beschwerden',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNworry',
                    prefix: '2_1',
                    required: true,
                    text: 'Sorge oder Angstzustände',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNsad',
                    prefix: '2_2',
                    required: true,
                    text: 'Traurigkeit oder Depression',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNenjoyment',
                    prefix: '2_3',
                    required: true,
                    text: 'Verlust von Interesse oder Freude',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNgrief',
                    prefix: '2_4',
                    required: true,
                    text: 'Kummer oder Verlust',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNfear',
                    prefix: '2_5',
                    required: true,
                    text: 'Angst',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNlonley',
                    prefix: '2_6',
                    required: true,
                    text: 'Einsamkeit',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNanger',
                    prefix: '2_7',
                    required: true,
                    text: 'Zorn',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNappearance',
                    prefix: '2_8',
                    required: true,
                    text: 'Veränderungen im Erscheinungsbild',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNworth',
                    prefix: '2_9',
                    required: true,
                    text: 'Gefühle der Wertlosigkeit oder das Gefühl, eine Belastung zu sein',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'NCNN_3',
            prefix: '3',
            required: true,
            text: 'Soziale Beschwerden',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNpartner',
                    prefix: '3_1',
                    required: true,
                    text: 'Beziehung zum Ehepartner oder Lebenspartner',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNchild',
                    prefix: '3_2',
                    required: true,
                    text: 'Beziehung zu Kindern',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNfamily',
                    prefix: '3_3',
                    required: true,
                    text: 'Beziehung zu Familienmitgliedern',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNfriends',
                    prefix: '3_4',
                    required: true,
                    text: 'Beziehung zu FreundInnen oder ArbeitskollegInnen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNhealthcare',
                    prefix: '3_5',
                    required: true,
                    text: 'Kommunikation mit dem Pflegeteam',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNprocreation',
                    prefix: '3_6',
                    required: true,
                    text: 'Fähigkeit, Kinder zu bekommen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNdiscrimination',
                    prefix: '3_7',
                    required: true,
                    text: 'Vorurteile oder Diskriminierung',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'NCNN_4',
            prefix: '4',
            required: true,
            text: 'Praktische Beschwerden',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNselfcare',
                    prefix: '4_1',
                    required: true,
                    text: 'Für mich selbst sorgen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNcare',
                    prefix: '4_2',
                    required: true,
                    text: 'Für andere sorgen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNwork',
                    prefix: '4_3',
                    required: true,
                    text: 'Arbeit',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNschool',
                    prefix: '4_4',
                    required: true,
                    text: 'Schule',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNhousing',
                    prefix: '4_5',
                    required: true,
                    text: 'Unterkunft',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNfinances',
                    prefix: '4_6',
                    required: true,
                    text: 'Finanzen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNinsurance',
                    prefix: '4_7',
                    required: true,
                    text: 'Versicherung',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNtransportation',
                    prefix: '4_8',
                    required: true,
                    text: 'Transport',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNchildcare',
                    prefix: '4_9',
                    required: true,
                    text: 'Kinderbetreuung',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNgroceries',
                    prefix: '4_10',
                    required: true,
                    text: 'Hinreichende Lebensmittelversorgung',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNmedication',
                    prefix: '4_11',
                    required: true,
                    text: 'Zugang zu Arzneimitteln',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCNNtreatment',
                    prefix: '4_12',
                    required: true,
                    text: 'Behandlungsentscheidungen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'group',
            disabledDisplay: 'protected',
            linkId: 'NCNN_5',
            prefix: '5',
            required: true,
            text: 'Spirituelle oder religiöse Beschwerden',
            item: [
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNmeaning',
                    prefix: '5_1',
                    required: true,
                    text: 'Verständnis für Sinn und Zweck',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNfaith',
                    prefix: '5_2',
                    required: true,
                    text: 'Veränderungen im Glauben oder in den Überzeugungen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNdeath',
                    prefix: '5_3',
                    required: true,
                    text: 'Tod, Sterben oder Leben nach dem Tod',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNconflict',
                    prefix: '5_4',
                    required: true,
                    text: 'Konflikt zwischen Überzeugungen und Krebsbehandlungen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNsacred',
                    prefix: '5_5',
                    required: true,
                    text: 'Beziehung zu den Heiligen',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'choice',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNritual',
                    prefix: '5_6',
                    required: true,
                    text: 'Rituelle oder diätetische Bedürfnisse',
                    answerOption: [
                        {
                            valueCoding: {
                                code: '1',
                                version: '1.0',
                                display: 'Ja',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        },
                        {
                            valueCoding: {
                                code: '0',
                                version: '1.0',
                                display: 'Nein',
                                system: 'http://uk-erlangen.de/teamx/valueCoding/YesNo_de'
                            }
                        }
                    ]
                },
                {
                    type: 'string',
                    disabledDisplay: 'protected',
                    linkId: 'NCCNother',
                    prefix: '5_7',
                    required: true,
                    text: 'Andere Probleme:'
                }
            ]
        }
    ]
};
