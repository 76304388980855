import type {Questionnaire} from '@refinio/one.models/lib/models/QuestionnaireModel';

export const DorAdoHitzewallungen: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://refinio.one/questionaire/DorAdoHitzewallungen',
    name: 'DorAdo_hitzewallungen',
    title: 'Hitzewallungen',
    status: 'active',
    item: [
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'info_1',
            prefix: '0_1',
            required: true,
            text: 'Während einer Krebstherapie können Patienten und Patientinnen verschiedene Symptome und Nebenwirkungen erfahren. Bitte beantworten Sie die folgenden Fragen, indem Sie die Antwort auswählen, die Ihre Erfahrung in den letzten sieben Tagen am besten beschreibt ...'
        },
        {
            type: 'display',
            disabledDisplay: 'protected',
            linkId: 'header_1',
            prefix: '0_2',
            required: true,
            text: 'HITZEWALLUNGEN'
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'ctcae_6a',
            prefix: '1',
            required: true,
            text: 'Während der letzten 7 Tage: Wie HÄUFIG hatten Sie HITZEWALLUNGEN?',
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Nie',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Selten',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Gelegentlich',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Häufig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Fast immer',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/Frequency_de'
                    }
                }
            ]
        },
        {
            type: 'choice',
            disabledDisplay: 'protected',
            linkId: 'ctcae_6b',
            prefix: '2',
            required: false,
            text: 'Während der letzten 7 Tage: Wie STARK waren Ihre HITZEWALLUNGEN im SCHLIMMSTEN FALL?',
            enableWhen: [
                {
                    operator: '!=',
                    question: 'ctcae_6a',
                    answerCoding: {
                        code: '0'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        code: '0',
                        version: '1.0',
                        display: 'Gar nicht',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '1',
                        version: '1.0',
                        display: 'Ein wenig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '2',
                        version: '1.0',
                        display: 'Mäßig',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '3',
                        version: '1.0',
                        display: 'Ziemlich',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                },
                {
                    valueCoding: {
                        code: '4',
                        version: '1.0',
                        display: 'Sehr',
                        system: 'http://uk-erlangen.de/teamx/valueCoding/NoneIntense_de'
                    }
                }
            ]
        }
    ]
};
