import type { Questionnaire } from "@refinio/one.models/lib/models/QuestionnaireModel.js";

export const DorAdoRegistrierung: Questionnaire = {
	resourceType: "Questionnaire",
	language: "de",
	url: "http://refinio.one/questionaire/DorAdoRegistrierung",
	title: "DorAdo Registrierung",
	name: "dorado_registrierung",
	status: "active",
	item: [
		{
			type: "open-choice",
			linkId: "consent",
			prefix: "2",
			required: true,
			text: "Sind Sie mit den Bedingungen der Studie und dem Datentransfer einverstanden?",
			disabledDisplay: "hidden",
			answerOption: [
				{
					valueCoding: {
						code: "1",
						version: "1.0",
						display:
							"Ich habe die Einverständniserklärung gelesen und unterschrieben.",
						system: "http://uk-erlangen.de/teamx/valueCoding/Consent_de",
					},
				},
			],
		},
	],
};
