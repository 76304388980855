import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import Button from '@mui/material/Button/Button.js';
import Typography from '@mui/material/Typography/Typography.js';

import landingHeaderImage from '@/gaia/resources/images/landing_header.png';
import landingFooterImage from '@/gaia/resources/images/landing_footer.png';

export default function WelcomePage(): ReactElement {
    const i18n = useTranslation();
    const navigate = useNavigate();

    function goToLogin(): void {
        navigate('login');
    }

    function goToRegister(): void {
        navigate('register');
    }

    return (
        <>
            <div className="landing-header">
                <img src={landingHeaderImage} alt="landing-header" />
            </div>

            <Typography className="welcome-header" component="h3">
                {i18n.t('gaia_registration.landing.header')}
            </Typography>

            <div className="welcome-info">
                <Typography className="register-header" component="h2">
                    {i18n.t('gaia_registration.landing.info')}
                </Typography>
            </div>

            <div className="welcome-buttons-container">
                <div className="welcome-buttons">
                    <Button className="login" onClick={goToLogin}>
                        {i18n.t('gaia_registration.buttons.common.login')}
                    </Button>
                    <Button onClick={goToRegister}>
                        {i18n.t('gaia_registration.buttons.common.register')}
                    </Button>
                </div>
            </div>

            <div className="landing-footer">
                <img src={landingFooterImage} alt="landing-footer" />
            </div>
        </>
    );
}
